import Swiper from "swiper";
import "swiper/scss";
import $ from "jquery";

(async () => {
  const categorySubs = document.querySelectorAll(".category__types .swiper");
  if (categorySubs.length) {
    categorySubs.forEach(function (categorySub) {
      const swiperInstance = new Swiper(categorySub, {
        slidesPerView: "auto",
        grabCursor: true,
      });

      const slideItems = categorySub.querySelectorAll(".swiper-slide");

      // Find the index of the slide with the 'active' class
      const activeSlideIndex = Array.from(slideItems).findIndex((item) =>
        item.classList.contains("active"),
      );

      // Set the active slide index if an active slide is found
      if (activeSlideIndex !== -1) {
        swiperInstance.slideTo(activeSlideIndex);
      }

      // Add click event listener to each slide item
      slideItems.forEach((slideItem, index) => {
        slideItem.addEventListener("click", () => {
          // Set the clicked slide as the active slide
          swiperInstance.slideTo(index);

          // Optional: Add or remove 'active' class based on your styling
          slideItems.forEach((item) => item.classList.remove("active"));
          slideItem.classList.add("active");
        });
      });

      // Update the Swiper instance
      swiperInstance.update();
    });
  }

  // GRID HANDLE
  // Function to handle class changes, set a cookie, and update active state
  function changeView(newClass, activeClass) {
    $(".product-listing")
      .removeClass(function (index, className) {
        return (className.match(/(^|\s)columns--\S+/g) || []).join(" ");
      })
      .addClass(newClass);

    document.cookie = "view=" + newClass + "; path=/"; // Session cookie

    // Update active class
    $(".category__grid-handle > .js-list-view, .category__grid-handle > .js-grid-view").removeClass(
      "active",
    );
    $(activeClass).addClass("active");
  }

  // Function to get a cookie value by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // Click event for the list view
  $(".js-list-view").on("click", function () {
    changeView("columns--2", ".js-list-view");
  });

  // Click event for the grid view
  $(".js-grid-view").on("click", function () {
    changeView("columns--5", ".js-grid-view");
  });

  // Apply the view from the cookie on page load
  const view = getCookie("view");
  if (view) {
    const activeClass = view === "columns--2" ? ".js-list-view" : ".js-grid-view";
    changeView(view, activeClass);
  }

  // GRID HANDLE END

  const BREAKPOINT = 768;

  // Utility functions
  const calculateScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  const updateScrollBarWidth = () => {
    const scrollBarWidth = calculateScrollBarWidth();
    $("body").css("--sb-width", `${scrollBarWidth}px`);
  };

  const updateTransform = ($el, transformValue) => {
    if ($el.length) {
      $el.css("transform", transformValue);
    }
  };

  const getTransformValue = (isVisible) => (isVisible ? "translateX(0)" : "translateX(-100%)");
  const $filters = $(".category__filters");

  const closeFilters = () => {
    const transformValue = getTransformValue(false);

    if ($filters.length) {
      updateTransform($filters, transformValue);
      $filters.removeClass("category__filters--open");
    }

    $("body").removeClass("no-scroll");
  };

  const toggleFilters = ($el) => {
    $el.on("click", function (event) {
      event.stopPropagation();
      const isVisible = $filters.hasClass("category__filters--open");
      const transformValue = getTransformValue(!isVisible);

      if ($filters.length) {
        updateTransform($filters, transformValue);

        if (!isVisible) {
          $filters.addClass("category__filters--open");
          $("body").addClass("no-scroll");
        } else {
          $filters.removeClass("category__filters--open");
          $("body").removeClass("no-scroll");
        }
      }
    });
  };

  const handleResize = () => {
    updateScrollBarWidth();
    // Close filters and reset transform when screen is resized above breakpoint
    if ($(window).width() >= BREAKPOINT) {
      closeFilters();
      $filters.css("transform", "");
    }
  };

  // Initial setup
  updateScrollBarWidth();
  toggleFilters($(".js-filters-toggle"));

  // Close filters
  $(".js-filters-close").on("click", (event) => {
    closeFilters();
  });

  // Listen to window resize event
  $(window).resize(handleResize);
  $(document).on("click", (event) => {
    if (
      $(window).width() < BREAKPOINT &&
      !$(event.target).closest(".category__filters, .js-filters-toggle").length
    ) {
      closeFilters();
    }
  });
})();
